import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Hero, ContactForms, Location, Footer } from '~/components'
import Banner from '~/assets/img/Contato.jpg'

const Contact = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title={'Contato - ' + process.env.GATSBY_SITE_NAME}
    />
    <Hero
      title="Contato"
      description={
        'Estamos preparados para atender você.\nRealize seu sonho com a Holder.'
      }
      image={Banner}
    />
    <ContactForms location={location} />
    <Location />
    <Footer />
  </>
)

export default Contact
